import React, { useState } from "react"
import { Link } from "gatsby"
import Login from "../components/Login"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => {
  const [loginNumber, setLoginNumber] = useState("")

  return (
    <Layout hideNav={true} hideFooter={true} title="Login">
      <Login />
    </Layout>
  )
}

export default IndexPage
